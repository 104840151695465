.css-12jo7m5 {
  color: #fff !important;
  font-size: 0.75rem;
}

.css-wsp0cs-MultiValueGeneric {
  color: #fff !important;
}

.css-9jq23d {
  color: #fff !important;
  font-size: 0.75rem;
}
