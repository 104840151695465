.sidebar {
  background-color: #333;
  color: #fff;
}
.chat-container {
  background-color: #fff;
  border: 1px solid #ddd;
}

.slack-container {
  height: 100vh;
}

.custom-scroll {
  overflow-y: auto;
  height: 100vh;
}
