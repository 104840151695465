a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
/* @media (min-width: 768px) {
  html {
    font-size: 16px;
  }
} */

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

/* .navbar {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.fs-nav-icon {
  font-size: calc(1.1rem + 0.4vw) !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-toggler-icon.active {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
*/

.navbar-light .nav-item {
  border-color: transparent !important;
}

.navbar-nav {
  flex-direction: row !important;
}

.navbar {
  max-height: 55px !important;
  display: flex !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
  /* justify-content: flex-start; */
}

.bottom-navbar {
  /* Add some margin to separate the Navbar from the main content */
  margin-top: 20px;
}

.nav-link-container {
  /* Use flexbox to evenly distribute the Navbar items */
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* Additional styling (optional) */
.navbar-brand {
  font-weight: bold;
}

.nav-link {
  color: #333;
  /* Add some padding to make the buttons more clickable */
  padding: 8px 16px;
}
