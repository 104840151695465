body {
  margin: 5px !important;
  overflow-x: hidden !important;
  padding-bottom: 100px !important;
}

.body-content {
  margin-bottom: 75px !important;
}

#home {
  margin-left: -5px !important;
  margin-right: -5px !important;
  margin-bottom: -75px !important;
}

.footer {
  margin-left: -5px !important;
  margin-right: -5px !important;
}

.btn-xs {
  font-weight: bold !important;
}

.sticky-top {
  top: 60px !important;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.3rem !important;
}

.avatar.avatar-4x3 {
  width: 4.1666666667rem;
  height: auto;
  border-radius: 0;
}

.avatar-sm.avatar-4x3 {
  width: 3.3333333333rem;
  height: auto;
  border-radius: 0;
}

.avatar-xl {
  width: 5.625rem;
  height: 5.625rem;
}

.profile-cover-avatar {
  display: flex;
  background-color: #fff;
  padding: 0.75rem 0.75rem;
  margin-top: -1.75rem;
}

.card-bordered {
  border-width: 0.0625rem;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

a {
  text-decoration: none !important;
}

.legend-indicator {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #bdc5d1;
  border-radius: 50%;
  margin-right: 0.4375rem;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.3125rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.mb-50 {
  margin-bottom: 50rem !important;
}

.mt-10 {
  margin-top: 10rem !important;
}

.form-check-input:checked {
  background-color: #2b4162 !important;
}

.form-check-input {
  margin-left: 1px;
}

.comment-container {
  min-width: 100%;
}
/* a:hover {
  color: #db504a !important;
} */

.btn-no-left-border-radius {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.btn-no-border-radius {
  border-radius: 0 !important;
}

.btn-x {
  border-top: 1px solid #d5d2dc !important;
  border-bottom: 1px solid #d5d2dc !important;
}

.editor-class {
  min-height: 150px !important;
}

.form-control-square-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/* .comment-editor-class {
  margin-right: -10px;
} */

.chat-thread {
  margin-bottom: 200px;
}

.card-nav-link {
  cursor: pointer !important;
}

.square-preview {
  width: 100%; /* Adjust to the desired width */
  height: 100%; /* 1:1 aspect ratio (height = width) */
  object-fit: cover; /* Preserve aspect ratio and cover the container */
}

.text-light a {
  color: #d5d2dc;
}

#contact-list {
  height: 100vh;
  overflow-y: scroll;
  border-right: 1px solid #e5e5e5;
}

#message-thread {
  height: 100vh;
  overflow-y: scroll;
  padding: 10px;
}

.contact-item {
  cursor: pointer;
}

.active-contact {
  background-color: #f0f0f0;
}

.scrollable {
  height: 100%;
  overflow-y: scroll;
}
/* a:hover {
  color: #db504a !important;
} */

.message-thread {
  position: absolute;
}

.text-info-light {
  color: #8cc6ef;
}

#fixed-chat-input {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.2);
}

@media (max-width: 500px) {
  .navbar-expand-sm .navbar-nav {
    margin-top: 0; /* Adjust as needed to center content */
    padding-top: 0; /* Adjust as needed to center content */
    border-top: none;
    border-radius: 0;
  }

  .navbar-expand-sm .navbar-nav .nav-item {
    margin-bottom: 0; /* Adjust as needed to center content */
    padding: 0; /* Adjust as needed to center content */
    border: 0;
    border-radius: 0;
  }
}

/* Styles for Firefox on Windows */
@media all and (-webkit-min-device-pixel-ratio: 0) {
  .overflow-auto {
    scrollbar-width: thin; /* Firefox on Windows */
    scrollbar-color: darkgray lightgray; /* Firefox on Windows */
  }
}

/* Styles for Firefox */
.overflow-auto {
  scrollbar-width: thin;
  scrollbar-color: darkgray lightgray;
}

/* Styles for Microsoft Edge and Internet Explorer */
.overflow-auto::-ms-scrollbar-thumb {
  background-color: darkgray;
}

.overflow-auto::-ms-scrollbar-track {
  background-color: lightgray;
}

/* Hover styles for Microsoft Edge and Internet Explorer */
.overflow-auto:hover::-ms-scrollbar-thumb {
  background-color: darkgray;
}

.overflow-auto:hover::-ms-scrollbar-track {
  background-color: lightgray;
}

/* Styles for Chrome on Windows */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .overflow-auto::-webkit-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: darkgray lightgray;
  }

  .overflow-auto::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }

  .overflow-auto::-webkit-scrollbar-track {
    background-color: lightgray;
  }

  /* Adjust styles for non-hover state on Chrome */
  .overflow-auto {
    overflow: auto;
  }
}
